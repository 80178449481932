import React from 'react';
import './App.css';

function App() {

  let a = 1;
  a = 2;

  return (
    <div className="App">
      <header className="App-header">
        Research and Development Laboratory

        <p style={{color: 'gray', fontSize: 16}}>
          ... soon will be shared more ...
        </p>

      </header>

      <footer>
        @ 2021 R&D Lab
      </footer>
    </div>
  );
}

export default App;
